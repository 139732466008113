<template>
  <div class="container">
    <div class="bg-line">
      <div class="bg-line-top"></div>
      <div class="bg-line-body"></div>
      <div class="bg-line-center"></div>
      <div class="bg-line-body"></div>
      <div class="bg-line-bottom"></div>
    </div>
    <div class="bg-tree"></div>
    <div class="bg-jl">
      <div class="bg-jl1"></div>
      <div class="bg-jl-flex"></div>
      <div class="bg-jl2"></div>
      <div class="bg-jl-flex"></div>
      <div class="bg-jl3"></div>
      <div class="bg-jl-flex"></div>
      <div class="bg-jl4"></div>
    </div>
    <div class="bg-shade" v-if="!isNormal"></div>
    <div class="main-con" :style="{height: (innerHeight >680 ? '85%' : '90%') }">
      <div class="logo"></div>
      <div style="flex:1;"></div>
      <div class="big-tit"></div>
      <div style="flex:1;"></div>
      <div v-if="isNormal" class="small-tit"></div>
      <div v-else class="small-line"></div>

      <div class="cj-hide" >
        <p>感谢您的关注</p>
        <p>请在微信中使用，参与活动！</p>
      </div>
      <div style="flex:1;"></div>
      <!-- <div style="flex:1;"></div> -->
      <!--  -->
      <div v-if="isNormal" class="btn-intro" @click="show = true"></div>
      <div v-else class="btn-look" @click="navToMain"></div>
    </div>
    <div id="map-container"></div>
    <van-overlay z-index="9999" :show="show" @click="show = false">
      <div class="wrapper">
        <div class="block">
          <div class="close" @click="show = false"></div>
          <div class="p-intro"></div>
          <div class="p-intro-cont">
            <p>1.微信扫描包装袋内二维码，即可参与抽奖活动</p>
            <p>2.在页面任意选择一个红包，喜兑现金大奖</p>
          </div>
          <div class="p-intro-desc">*本活动所有解释权归品上品槟郎有限公司所有</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import Vue from "vue";
import {Overlay } from "vant";
Vue.use(Overlay);
export default {
  name: "error",
  data() {
    return {
      userInfo: null,
      innerHeight: 0,
      flopStatus: false,
      isNormal: true,
      really: "",
      whether: false,
      surplus: false,
      implement: 0,
      province: null,
      city: null,
      district: null,
      posLeft: ["left1", "left2", "left3"],
      cjShow: true,
      code: "",
      amountList: [],
      show: false,
      amount: 0
    };
  },
  components: {
  },
  created() {
    this.innerHeight = window.innerHeight;
  },
  mounted() {
    
  },
  methods: {

  }
};
</script>

<style lang="scss" scoped>
div {
  box-sizing: border-box;
}
#map-container {
  z-index: -10;
  position: fixed;
  top: 0;
  left: 0;
}
.container {
  z-index: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  background: url("../assets/images/bg-main.png") repeat;
  .bg-line {
    width: 100%;
    height: 98%;
    z-index: 10;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      width: 100%;
    }
    .bg-line-top {
      height: 30px;
      background: url("../assets/images/bg-k1.png") no-repeat;
      background-size: cover;
    }
    .bg-line-body {
      flex: 1;
      background: url("../assets/images/bg-line.png") repeat-y;
      // background-position: 10px -4px;
      background-size: 100%;
    }
    .bg-line-center {
      height: 76px;
      background: url("../assets/images/bg-k2.png") no-repeat;
      background-size: 100%;
    }
    .bg-line-bottom {
      height: 30px;
      background: url("../assets/images/bg-k3.png") no-repeat;
      background-size: 100%;
    }
  }
  .bg-tree {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 109px;
    background: url("../assets/images/bg-tree.png") no-repeat;
    background-size: cover;
  }
  .bg-jl {
    width: 100%;
    height: 100%;
    z-index: 3;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    div {
      width: 92.7%;
    }
    .bg-jl-flex {
      flex: 1;
    }
    .bg-jl1 {
      margin-top: 46px;
      height: 107px;
      background: url("../assets/images/fish1.png") no-repeat;
      background-size: 100%;
      // background-size: 384px;
      // background-position: 14px 0px;
    }
    .bg-jl2 {
      height: 138px;
      background: url("../assets/images/fish2.png") no-repeat;
      background-size: 100%;
      // background-size: 384px;
      // background-position: 14px 0px;
    }
    .bg-jl3 {
      height: 138px;
      background: url("../assets/images/fish3.png") no-repeat;
      background-size: 100%;
      // background-size: 384px;
      // background-position: 14px 0px;
    }
    .bg-jl4 {
      height: 164px;
      background: url("../assets/images/fish4.png") no-repeat;
      background-size: 100%;
      // background-size: 384px;
      // background-position: 14px 0px;
    }
  }
  .bg-shade {
    position: fixed;
    left: 0;
    z-index: 2;
    top: 121px;
    width: 100%;
    height: 414px;
    background: url("../assets/images/bg-shade.png") repeat;
    background-size: cover;
    animation: spin 10s linear infinite;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
      transition: all 10s;
    }
  }
  .main-con {
    position: fixed;
    top: 5%;
    left: 0;
    z-index: 15;
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .logo {
      // margin-top: 22px;
      width: 116px;
      height: 83px;
      background: url("../assets/images/logo.png") no-repeat;
      background-size: cover;
    }
    .big-tit {
      margin-top: 3px;
      width: 344px;
      height: 82px;
      background: url("../assets/images/big-tit.png") no-repeat;
      background-size: cover;
    }
    .small-tit {
      margin-top: -22px;
      width: 292px;
      height: 62px;
      background: url("../assets/images/small-tit.png") no-repeat;
      background-size: cover;
    }
    .small-line {
      width: 292px;
      height: 20px;
    }
    .cj-wrapper {
      // margin-top: 14px;
      width: 100%;
      height: 350px;
      // flex: 1;
      position: relative;
    }
    .cj-hide {
      width: 100%;
      height: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #f5a623;
    }
    .btn-intro {
      // margin-bottom: 28px;
      width: 132px;
      height: 28px;
      background: url("../assets/images/btn-intr.png") no-repeat;
      background-size: cover;
    }
    .btn-look {
      // margin-bottom: 18px;
      width: 345px;
      height: 89px;
      background: url("../assets/images/btn-look.png") no-repeat;
      background-size: cover;
    }
  }
}

.cj-card {
  position: absolute;
  top: 0px;
  width: 110px;
  height: 161px;
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  .btn-click {
    margin-bottom: 24px;
    width: 61px;
    height: 24px;
    background: url("../assets/images/btn-click.png") no-repeat;
    background-size: cover;
    animation: breathe 1.5s infinite;
  }
  .cj-end {
    width: 65%;
    height: 65%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: url("../assets/images/zjiang.png") no-repeat;
    background-size: 100%;
    margin-bottom: 12px;
    // background-position: 20px 35px;
  }
  .cj-zero {
    background: url("../assets/images/xxhg.png") no-repeat;
    background-size: 100%;
  }
  .cj-other {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .zj-count {
    padding-left: 15px;
    margin-bottom: 15px;
    position: relative;
    font-family: LiGothicMed;
    font-size: 30px;
    color: #ffe7e7;
  }
  .zj-count::before {
    position: absolute;
    top: 8px;
    left: -2px;
    content: "¥";
    font-family: LiGothicMed;
    font-size: 20px;
    color: #ffe7e7;
  }
  .mar-bt {
    margin-bottom: 0;
  }
}
@keyframes breathe {
  0% {
    transform: scale(0.88);
  }
  50% {
    transform: scale(1.03);
  }
  100% {
    transform: scale(0.88);
  }
}
.isZj {
  justify-content: center;
}
.cinton1 {
  top: 0px;
  left: 73px;
  transform: rotate(-6deg);
  background: url("../assets/images/gong1.png") no-repeat;
  background-size: cover;
  animation: cinton1 alternate linear 2 1s;
}
@keyframes cinton1 {
  from {
    top: 0px;
    left: 73px;
    transform: rotate(0deg);
  }
  to {
    top: 55px;
    left: 153px;
    transform: rotate(6deg);
  }
}
.cinton2 {
  top: 0px;
  left: 221px;
  transform: rotate(6deg);
  background: url("../assets/images/xi1.png") no-repeat;
  background-size: cover;
  animation: cinton2 alternate linear 2 1s;
}
@keyframes cinton2 {
  from {
    top: 0px;
    left: 221px;
    transform: rotate(0deg);
  }
  to {
    top: 55px;
    left: 153px;
    transform: rotate(6deg);
  }
}
.cinton3 {
  top: 182px;
  left: 73px;
  transform: rotate(-6deg);
  background: url("../assets/images/fa1.png") no-repeat;
  background-size: cover;
  animation: cinton3 alternate linear 2 1s;
}
@keyframes cinton3 {
  from {
    top: 182px;
    left: 73px;
    transform: rotate(0deg);
  }
  to {
    top: 55px;
    left: 153px;
    transform: rotate(-6deg);
  }
}
.cinton4 {
  top: 182px;
  left: 221px;
  transform: rotate(6deg);
  background: url("../assets/images/cai1.png") no-repeat;
  background-size: cover;
  animation: cinton4 alternate linear 2 1s;
}
@keyframes cinton4 {
  from {
    top: 182px;
    left: 221px;
    transform: rotate(0deg);
  }
  to {
    top: 55px;
    left: 153px;
    transform: rotate(6deg);
  }
}
.move1 {
  top: 190px;
  transform: scale(0.78);
  // animation: move1 linear 1 1s;
  animation-name: move1, fanzhuan;
  animation-duration: 1000ms, 1000ms;
  animation-timing-function: linear, linear;
  animation-delay: 0, 2000ms;
  animation-iteration-count: 1, 1;
  background: url("../assets/images/gong2.png") no-repeat;
  background-size: cover;
}
.select1 {
  background: url("../assets/images/gong2.png") no-repeat;
  background-size: cover;
}
@keyframes move1 {
  from {
    top: 0px;
    left: 73px;
    transform: scale(1);
    background: url("../assets/images/gong1.png") no-repeat;
    background-size: cover;
  }
  to {
    top: 190px;
    // left: 50px;
    transform: scale(0.78);
    background: url("../assets/images/gong2.png") no-repeat;
    background-size: cover;
  }
}
.move2 {
  top: 190px;
  transform: scale(0.78);
  // animation: move2 linear 1 1s;
  animation-name: move2, fanzhuan;
  animation-duration: 1000ms, 1000ms;
  animation-timing-function: linear, linear;
  animation-delay: 0, 2000ms;
  animation-iteration-count: 1, 1;
  background: url("../assets/images/xi2.png") no-repeat;
  background-size: cover;
}
.select2 {
  background: url("../assets/images/xi2.png") no-repeat;
  background-size: cover;
}
@keyframes move2 {
  from {
    top: 0px;
    left: 221px;
    transform: scale(1);
    background: url("../assets/images/xi1.png") no-repeat;
    background-size: cover;
  }
  to {
    top: 190px;
    transform: scale(0.78);
    background: url("../assets/images/xi2.png") no-repeat;
    background-size: cover;
  }
}
.move3 {
  top: 190px;
  transform: scale(0.78);
  // animation: move3 linear 1 1s;
  animation-name: move3, fanzhuan;
  animation-duration: 1000ms, 1000ms;
  animation-timing-function: linear, linear;
  animation-delay: 0, 2000ms;
  animation-iteration-count: 1, 1;
  background: url("../assets/images/fa2.png") no-repeat;
  background-size: cover;
}
.select3 {
  background: url("../assets/images/fa2.png") no-repeat;
  background-size: cover;
}
@keyframes move3 {
  from {
    top: 182px;
    left: 73px;
    transform: scale(1);
    background: url("../assets/images/fa1.png") no-repeat;
    background-size: cover;
  }
  to {
    top: 190px;
    transform: scale(0.78);
    background: url("../assets/images/fa2.png") no-repeat;
    background-size: cover;
  }
}
.move4 {
  top: 190px;
  transform: scale(0.78);
  // animation: move4 linear 1 1s;
  animation-name: move4, fanzhuan;
  animation-duration: 1000ms, 1000ms;
  animation-timing-function: linear, linear;
  animation-delay: 0, 2000ms;
  animation-iteration-count: 1, 1;
  background: url("../assets/images/cai2.png") no-repeat;
  background-size: cover;
}
.select4 {
  background: url("../assets/images/cai2.png") no-repeat;
  background-size: cover;
}
@keyframes move4 {
  from {
    top: 190px;
    left: 221px;
    transform: scale(1);
    background: url("../assets/images/cai1.png") no-repeat;
    background-size: cover;
  }
  to {
    top: 190px;
    transform: scale(0.78);
    background: url("../assets/images/cai2.png") no-repeat;
    background-size: cover;
  }
}
.selectMove1 {
  top: 0px;
  left: 150px;
  // animation: selectMove1 linear 1 1s;
  animation-name: selectMove1, fanzhuan;
  animation-duration: 1000ms, 1000ms;
  animation-timing-function: linear, linear;
  animation-delay: 0, 500ms;
  animation-iteration-count: 1, 1;
  transform: scale(1.46);
  background: url("../assets/images/gong2.png") no-repeat;
  background-size: cover;
}
@keyframes selectMove1 {
  from {
    top: 0px;
    left: 73px;
    transform: scale(1);
    background: url("../assets/images/gong1.png") no-repeat;
    background-size: cover;
  }
  to {
    top: 0px;
    left: 150px;
    transform: scale(1.46);
    background: url("../assets/images/gong2.png") no-repeat;
    background-size: cover;
  }
}
.selectMove2 {
  top: 0px;
  left: 150px;
  // animation: selectMove2 linear 1 1s;
  animation-name: selectMove2, fanzhuan;
  animation-duration: 1000ms, 1000ms;
  animation-timing-function: linear, linear;
  animation-delay: 0, 1000ms;
  animation-iteration-count: 1, 1;
  transform: scale(1.46);
  background: url("../assets/images/xi2.png") no-repeat;
  background-size: cover;
}
@keyframes selectMove2 {
  from {
    top: 0px;
    left: 221px;
    transform: scale(1);
    background: url("../assets/images/xi1.png") no-repeat;
    background-size: cover;
  }
  to {
    top: 0px;
    left: 150px;
    transform: scale(1.46);
    background: url("../assets/images/xi2.png") no-repeat;
    background-size: cover;
  }
}
.selectMove3 {
  top: 0px;
  left: 150px;
  // animation: selectMove3 linear 1 1s;
  animation-name: selectMove3, fanzhuan;
  animation-duration: 1000ms, 1000ms;
  animation-timing-function: linear, linear;
  animation-delay: 0, 1000ms;
  animation-iteration-count: 1, 1;
  transform: scale(1.46);
  background: url("../assets/images/fa2.png") no-repeat;
  background-size: cover;
}
@keyframes selectMove3 {
  from {
    top: 182px;
    left: 73px;
    transform: scale(1);
    background: url("../assets/images/fa1.png") no-repeat;
    background-size: cover;
  }
  to {
    top: 0px;
    left: 150px;
    transform: scale(1.46);
    background: url("../assets/images/fa2.png") no-repeat;
    background-size: cover;
  }
}
.selectMove4 {
  top: 0px;
  left: 150px;
  // animation: selectMove4 linear 1 1s;
  animation-name: selectMove4, fanzhuan;
  animation-duration: 1000ms, 1000ms;
  animation-timing-function: linear, linear;
  animation-delay: 0, 1000ms;
  animation-iteration-count: 1, 1;
  transform: scale(1.46);
  background: url("../assets/images/cai2.png") no-repeat;
  background-size: cover;
}
@keyframes selectMove4 {
  from {
    top: 182px;
    left: 221px;
    transform: scale(1);
    background: url("../assets/images/cai1.png") no-repeat;
    background-size: cover;
  }
  to {
    top: 0px;
    left: 150px;
    transform: scale(1.46);
    background: url("../assets/images/cai2.png") no-repeat;
    background-size: cover;
  }
}
.left1 {
  left: 50px;
}
.left2 {
  left: 156px;
}
.left3 {
  left: 262px;
}
.animt {
  animation: fanzhuan 2s;
}
@keyframes fanzhuan {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(-180deg);
  }
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  position: relative;
  width: 350px;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 20px;
  .close {
    top: 10px;
    right: 10px;
    position: absolute;
    width: 20px;
    height: 20px;
    background: url("../assets/images/close.png") no-repeat;
    background-size: 20px 20px;
  }
  .p-intro {
    margin-bottom: 15px;
    width: 159px;
    height: 34px;
    background: url("../assets/images/p-intro.png") no-repeat;
    background-size: cover;
  }
  .p-intro-cont {
    width: 300px;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    p {
      line-height: 28px;
    }
  }
  .p-intro-desc {
    width: 300px;
    text-align: left;
    font-size: 12px;
    color: #8e8e8e;
  }
}
</style>